<template>
    <div class="w-100">
        <!-- Header -->
        <div class="text-center mb-5">
            <p class="mb-3">
                <i class="fa fa-2x fa-circle-notch text-primary-light"></i>
            </p>
            <h1 class="fw-bold mb-2">
                Lupa Password
            </h1>
            <p class="fw-medium text-muted">
                Masukkan email anda dan tekan tombol Request OTP, <br/>kami akan mengirimkan kode OTP agar anda dapat mereset
                password anda.
            </p>
        </div>

        <div class="row g-0 justify-content-center">
            <div class="col-sm-8 col-xl-8">
                <form class="js-validation-reminder">
                    <div class="row">
                        <div class="col-md-8 mb-4">
                            <input type="text" class="form-control form-control-lg form-control-alt py-3" id="reminder-credential" name="reminder-credential" v-model="auth.email" placeholder="Alamat Email Terdaftar">
                        </div>
                        <div class="col-md-4 mb-4">
                            <button type="submit" class="btn btn-lg btn-alt-primary" @click="requestOTP" :disabled="loading.submit">
                                <i class="fa fa-fw fa-envelope me-1 opacity-50"></i> Request OTP
                            </button>
                        </div>
                        <div class="col-md-12 mb-4">
                            <p class="fw-medium text-muted">Masukkan kode OTP yang kami kirimkan ke emailmu</p>
                            <v-otp-input
                                id="kode-otp"
                                length="6"
                                type="number"
                                class="password-otp"
                                v-model="otp"
                                :disabled="loading.submit"
                                @finish="requestReset"
                            ></v-otp-input>
                        </div>
                    </div>
                    <div class="text-center">
                        <p class="fw-medium text-muted"><a href="/login">Masuk?</a></p>
                    </div>
                </form>
            </div>
        </div>
        <!-- END Reminder Form -->
    </div>
</template>

<script>

import axios from 'axios'
export default {
    components: {

    },

    data: () => ({
        auth: {
            email: '',
        },
        otp: '',

        loading: {
            submit: false
        }

    }),

    methods: {
        requestOTP() {
            this.loading.submit = true

            return new Promise(() => {
                axios.post('requestOTP', {

                    email: this.auth.email,

                }, {
                    headers: {
                        'Access-Control-Allow-Headers': '*',
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then(response => {

                    this.openNotification('top-right', 'success', '10000', 'Sukses', response.data.message);
                    this.loading.submit = false

                })

                    .catch(error => {
                        this.openNotification('top-right', 'danger', '10000', 'Autentikasi Gagal', error.response.data.message);
                        this.loading.submit = false
                    })

            })
        },

        requestReset() {
            this.loading.submit = true

            return new Promise(() => {
                axios.post('requestReset', {

                    email: this.auth.email,
                    otp: this.otp,

                }, {
                    headers: {
                        'Access-Control-Allow-Headers': '*',
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then(response => {
                    console.log(response.message)
                    localStorage.setItem('requested_email', this.auth.email)
                    this.$router.push({ name: 'Reset' })
                    this.loading.submit = false

                })

                    .catch(error => {
                        this.openNotification('top-right', 'danger', '10000', 'Autentikasi Gagal', error.response.data.message);
                        this.loading.submit = false
                    })

            })
        },

        openNotification(position = null, color, duration, title, message) {
            this.$vs.notification({
                color,
                position,
                duration,
                title: title,
                text: message
            })
        },
    }
}

</script>

<style scoped>
@import '../../../public/oneui/assets/css/oneui.min.css';
</style>
